<template>
    <div>
        <Dialog v-if="infoFlag" :create="true" @on-close="closeIDsPopup" title="航空器登记" :left="140" :top="105"
            :width="1748">
            <!--待审批 -->
            <div class="Box">
                <!-- tableSelect -->
                <div class="tableSelect ">
                    <div class="w204 h109 mr24 item cp" @click="approvalStatusClick(index)"
                        :class="[item.default, { selected: edit_form.type == index + 1 }]"
                        v-for="(item, index) in approvalStatusNum" :key="index">
                        <div class="jc-tp">
                            <img class="w50 h50" :src="item.icon" alt="">
                        </div>
                        <div>
                            <div class="fwb f34 " :style='getColor(item.color)'>
                            </div>
                            <div class="cf f16"> {{ item.title }}</div>
                        </div>
                    </div>
                </div>
                <!-- 赛选栏 -->
                <div class="select">
                    <el-input v-model="edit_form.manufacturerName" class="w170 ml10 mr10 dib " size="mini"
                        placeholder="请输入生产厂商" clearable>
                    </el-input>

                    <el-select v-model="edit_form.productType" size="mini" clearable placeholder="请选择产品类型"
                        class="button">
                        <el-option v-for="item in drd.listProductList" :key="item.id" :label="item.type"
                            :value="item.id" />
                    </el-select>
                    <el-input v-model="edit_form.aircraftName" class="w170 mr10 dib " size="mini" placeholder="请输入无人机型号"
                        clearable>
                    </el-input>
                    <el-select v-model="edit_form.areaBelongs" size="mini" clearable placeholder="请选择所属辖区"
                        class="button">
                        <el-option v-for="item in drd.prefectureList" :key="item.id" :label="item.title"
                            :value="item.title" />
                    </el-select>

                    <el-select v-model="edit_form.ownerType" size="mini" clearable placeholder="请选择用户类型" class="button">
                        <el-option v-for="item in drd.cates" :key="item.id" :label="item.title" :value="item.id" />
                    </el-select>
                    <el-select filterable v-model="edit_form.purpose" size="mini" clearable placeholder="请选择用途"
                        class="button">
                        <el-option v-for="item in drd.typeList" :key="item.id" :label="item.name" :value="item.id" />
                    </el-select>
                    <el-select filterable v-model="edit_form.purchaseChannel" size="mini" clearable
                        placeholder="请选择购买渠道" class="button">
                        <el-option v-for="item in drd.purchaseChannelList" :key="item.id" :label="item.title"
                            :value="item.title" />
                    </el-select>
                    <el-select v-show="edit_form.type == 2" filterable v-model="edit_form.messageStatus" size="mini"
                        clearable placeholder="请选择审批状态" class="button">
                        <el-option v-for="item in drd.messageStatusList" :key="item.id" :label="item.name"
                            :value="item.name" />
                    </el-select>


                    <div class="mt20 ml10 jcsb w800 h30 ">
                        <el-date-picker class="dib" value-format="yyyy-MM-dd HH:mm:ss" v-model="edit_form.purchaseTime"
                            type="datetime" placeholder="请选择购买日期">
                        </el-date-picker>
                        <el-input v-model="edit_form.userIdPhone" class="w300 ml20  mr10 dib "
                            placeholder="请输入姓名、手机号、身份证号" clearable>
                        </el-input>
                        <div class="mt5 jcsb w400">
                            <div class="btn" @click="searchData">查询</div>
                            <div class="btn " @click="reset">重置</div>
                            <div class="btn">
                                <JsonExcel :fetch='fetch' :fields="json_fields" name="登记统计.xls">
                                    一键导出
                                </JsonExcel>

                            </div>
                            <el-upload method="post" :auto-upload="true" :headers="headers" :show-file-list="false"
                                accept=".xlsx, .xls" :multiple="false" :http-request="(param) => airUpload(param)"
                                :on-success="onUploadSuccess" action="#" class="upload-demo">
                                <!-- <el-button size="small" type="primary">点击上传</el-button> -->
                                <div class="btn dib">导入</div>
                            </el-upload>




                        </div>
                    </div>



                </div>
                <!-- 表格 -->
                <Table class="task-table" :columns="columns" :dataSource="dataSource" :pagination="pagination"
                    @size-change="
                    (size) => {
                        pagination.size = size;
                        async_get_list();
                    }" @page-change="(page) => { async_get_list(page) }">


                    <template #index="{ row }">
                        <div class="action">
                            {{ row.index }}
                        </div>
                    </template>
                    <template #ownerType="{ row }">
                        <div class="action">
                            {{ row.ownerType == 1 ? '个人 ' : '单位' }}
                        </div>
                    </template>
                    <template #areaBelongs="{ row }">
                        <div class="action">
                            {{ prefecture[row.areaBelongs] || row.areaBelongs }}
                        </div>
                    </template>

                    <template #action="{ row }">
                        <div class="action">
                            <div v-if="edit_form.type == 1&&isJurisdiction">
                                <el-tooltip content="审批" placement="top" effect="light">
                                    <div class="cp iconfont f4f7 f20 icon-baogaoshenhe" @click="approval(row)"></div>
                                </el-tooltip>
                            </div>
                            <div v-else>
                                <el-tooltip content="详情" placement="top" effect="light">
                                    <div class="cp iconfont f4f7 f20 icon-chakanxiangqing" @click="approval(row)"></div>
                                </el-tooltip>
                            </div>


                        </div>
                    </template>
                </Table>
            </div>
        </Dialog>
        <Dialog v-else :create="true" @on-close="closeIDsPopupDialog" :title="edit_form.type == 1 ? '航空器登记审批' : '详情'"
            :left="140" :top="105" :width="1000">
            <div class="djInfoBox cf wh100">
                <div class="wh100" v-if="approvalList != null">
                    <div class="mb20 f16"><span class="dec dib mr10"></span> 设备信息</div>
                    <div class="item">
                        <div>生产厂商：<span>{{ approvalList.manufacturerName }}</span></div>
                        <div>无人机型号：<span>{{ approvalList.aircraftName }}</span></div>
                        <div>产品类别：<span>{{ approvalList.productSortName }}</span></div>
                        <div>产品类型：<span>{{ approvalList.productTypeName }}</span></div>
                        <div>空机重量：<span>{{ approvalList.deviceWeight }}kg</span></div>
                        <div>产品序列号：<span>{{ approvalList.productNumber }}</span></div>
                        <div>飞控序列号：<span>{{ approvalList.flightControlNumber }}</span></div>
                        <div></div>
                    </div>
                    <el-divider></el-divider>
                    <div class="mb20 f16"><span class="dec dib mr10"></span> 所有人信息</div>
                    <div class="item">
                        <div>所有人姓名:<span>{{ approvalList.ownerName }}</span></div>
                        <div>证件号码:<span>{{ approvalList.ownerIdNo }}</span></div>
                        <div>用户类型:<span>{{ approvalList.ownerType == 1 ? "个人" : "单位" }}</span></div>

                        <div>联系电话：<span>{{ approvalList.ownerPhone }}</span></div>
                        <div>标签:<span>{{ approvalList.label }}</span></div>
                        <div>飞控账号：<span>{{ approvalList.flightControlAccount }}</span></div>
                        <div>购买渠道：<span>{{ approvalList.purchaseChannel }}</span></div>
                        <div>购买时间：<span>{{ approvalList.purchaseTime }}</span></div>
                        <div>所属辖区:<span> {{ this.prefecture[approvalList.areaBelongs] || approvalList.areaBelongs
                        }}</span></div>
                        <div>详细地址:<span>{{ approvalList.address }}</span></div>
                        <div>存放场所:<span>{{ approvalList.deposit }}</span></div>
                        <div>保管人:<span>{{ approvalList.preserver }}</span></div>
                        <div>用途:<span>{{ approvalList.usagesName }}</span></div>
                        <div>备注:<span>{{ approvalList.remark }}</span></div>

                    </div>
                    <el-divider></el-divider>
                    <div class="mb20 f16"><span class="dec dib mr10"></span> 保险信息</div>
                    <div class="item">
                        <div>是否第三者责任购买:<span>{{ approvalList.isBuyInsurance == 1 ? '否' : '是' }}</span></div>
                        <div></div>
                        <div></div>
                        <div v-show="approvalList.isBuyInsurance == 2">保险公司名称:<span>{{ approvalList.insuranceCompany
                        }}</span></div>
                        <div v-show="approvalList.isBuyInsurance == 2">保险单号:<span>{{ approvalList.insuranceNumber
                        }}</span></div>
                        <div v-show="approvalList.isBuyInsurance == 2">保额:<span>{{ approvalList.insuranceSum }}元</span>
                        </div>
                        <div v-show="approvalList.isBuyInsurance == 2">保险开始时间:<span>{{ approvalList.insuranceStartTime
                        }}</span></div>
                        <div v-show="approvalList.isBuyInsurance == 2">保险结束时间:<span>{{ approvalList.insuranceEndTime
                        }}</span></div>
                        <div v-show="approvalList.isBuyInsurance == 2"></div>
                        <div>拥有凭证:<span class="cp" style="color:#2727fd"
                                @click="showImg(approvalList.information)">查看凭证</span> </div>
                        <div>保单:<span class="cp" style="color:#2727fd" @click="showImg(approvalList.policy)">查看保单</span>
                        </div>

                    </div>
                    <el-divider></el-divider>

                    <div class="mb20 f16"><span class="dec dib mr10"></span> 设备照片</div>
                    <div class="item">
                        <div>设备照片:<span class="cp" style="color:#2727fd"
                                @click="showImg(approvalList.aircraftPhotos)">查看照片 </span></div>
                        <div>序列号照片:<span class="cp" style="color:#2727fd"
                                @click="showImg(approvalList.serialNumberPhotos)">查看照片</span></div>
                    </div>
                    <el-divider></el-divider>

                    <!-- <div class="jcsb "  v-if="edit_form.type == 2"><span class="dib w120"> 审批结果:</span>
                        <el-input type="textarea" :rows="2" v-model="approvalList.approvalRemark" disabled>
                        </el-input>
                    </div> -->
                    <div v-if="edit_form.type == 2">
                        <div class="mb20 f16"><span class="dec dib mr10"></span> 航空器审批结果</div>
                        <div class="item">
                            <div>审批结果:<span v-if="approvalList.messageStatus == 3" style="color:green">审核通过</span>
                                <span v-if="approvalList.messageStatus == 4" style="color:red">审核不通过</span>
                            </div>
                            <div></div>
                            <div></div>

                            <div class="jcsb ">
                                <div class=" w60 "> 审批备注:
                                    <el-input type="textarea" class="w800 vat" :rows="2" disabled
                                        v-model="approvalList.auditRemark">
                                    </el-input>

                                </div>

                            </div>
                        </div>
                    </div>
                    <div v-if="edit_form.type == 1&&isJurisdiction">
                        <div class="mb20 f16"><span class="dec dib mr10"></span> 航空器登记审批</div>

                        <div class="item">
                            <div>请选择审批结果:<span style="    vertical-align: top;">
                                    <el-select v-model="isApproval" size="mini" clearable placeholder="请选择审批结果"
                                        class="button">
                                        <el-option v-for="item in isApprovalType" :key="item.id" :label="item.title"
                                            :value="item.id" />
                                    </el-select>
                                </span></div>
                            <div></div>
                            <div></div>


                        </div>
                        <div class="jcsb " v-if="edit_form.type == 1"><span class="dib w60" style="color:#8799b7">
                                备注:</span>
                            <el-input type="textarea" :rows="2" placeholder="请输入审批意见" v-model="textarea">
                            </el-input>
                        </div>
                    </div>
                    <div class="mt20 tc wih00" style="margin:0 auto">
                        <div class="dib cf" v-if="edit_form.type == 1&&isJurisdiction">

                            <div class="btn" @click="approvalHandClick">提交</div>
                        </div>
                        <div class=" btn" @click="back">返回</div>


                    </div>

                </div>

            </div>
        </Dialog>
        <ImgDialog :imgFlag='false' title="预览图片" :src='src' :imgList="imgList" v-if="ImgDialogFlag"
            @imgDialogClose="ImgDialogFlag = false">
        </ImgDialog>
    </div>
</template>
<script>
import Dialog from "@/pages/civilAircraft/components/dialog"
import ImgDialog from "@/pages/civilAircraft/components/imgDialog"

import Table from "@/components/common/table-new-hwb"; // 列表组件
import API from "@/api";
import JsonExcel from 'vue-json-excel'
import VueViewer from "v-viewer";
import Vue from "vue";
Vue.use(VueViewer);
import "viewerjs/dist/viewer.css";
import { mapGetters } from 'vuex'
export default {
    components: { Dialog, Table, JsonExcel, ImgDialog },
    computed:{
       ...mapGetters(['user_info_hn'])
    },
    data() {
        return {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            // 所属辖区
            baseUrl: "",
            prefecture: {
                1: '许村镇',
                2: '长安镇',
                3: '周王庙镇',
                4: '盐官镇',
                5: '丁桥镇',
                6: '斜桥镇',
                7: '袁花镇',
                8: '黄湾镇',
                9: '硖石街道',
                10: '海洲街道',
                11: '海昌街道',
                12: '马桥街道',
            },

            // 审批结果
            isApproval: null,
            isApprovalType: [
                { id: 3, title: "审批通过" },
                { id: 4, title: "拒绝" }
            ],
            ImgDialogFlag: false,
            src: "",
            imgList: [],
            textarea: '',
            approvalVisible: false,
            approvalStatusNum: [
                {
                    title: '待审批',
                    num: 12,
                    default: 'dsp',
                    icon: require('@/assets/images/civilAircraft/dsp.svg'),
                    color: "#50F9F4FF"

                },
                {
                    title: '已审批',
                    num: 12,
                    default: "sptg",
                    icon: require('@/assets/images/civilAircraft/sptg.svg'),
                    color: "#00C4FF"

                }
            ],
            dataSource: [
            ],
            dataSource2: [],
            json_fields: {
                "序号": "index",    //常规字段
                "登记日期": "createTime",
                "生产厂商": "manufacturerName",
                "产品类型": "productTypeName",
                "无人机型号": "aircraftName",
                "所有人姓名": "ownerName",
                "所有人-用户类型(1个人/2单位)": "ownerType",
                "联系手机": "ownerPhone",
                "产品类别：": "productSortName",
                "空机重量（kg）：": "deviceWeight",
                "产品序列号：": "productNumber",
                "飞控序列号：": "flightControlNumber",
                "所有人-证件号码": "ownerIdNo",
                "所有人-联系电话": "ownerPhone",
                "标签": "label",
                "飞控账号": "flightControlAccount",
                "购买渠道": "purchaseChannel",
                "购买时间": "purchaseTime",
                "所属辖区": "areaBelongs",
                "详细地址": "address",
                "存放场所": "deposit",
                "保管人": "preserver",
                "用途": "usagesName",
                "备注": "remark",
                "是否第三者责任购买": "isBuyInsurance",
                "保险公司名称": "insuranceCompany",
                "保险单号": "insuranceNumber",
                "保额(元)": "insuranceSum",
                "保险开始时间": "isBuyInsurance",
                "保险结束时间": "insuranceCompany",
                "凭证链接": "information",
                "保单链接": "policy",
                "设备照片": "aircraftPhotos",
                "序列号照片": "serialNumberPhotos",
                "审批状态": "messageStatus",
                "审批备注": "auditRemark",
            },
            columns: [
                { prop: "index", label: "序号", slot: true, width: "100px" },
                { prop: "createTime", label: "登记日期", width: "230px" },
                { prop: "manufacturerName", label: "生产厂商", width: "170px" },
                { prop: "productTypeName", label: "产品类型", width: "170px" },
                { prop: "aircraftName", label: "无人机型号", width: "170px" },
                { prop: "areaBelongs", slot: true, label: "所属辖区", width: "170px" },
                { prop: "ownerName", label: "所有人", width: "230px" },
                { prop: "ownerPhone", label: "联系手机", width: "170px" },
                { prop: "ownerType", slot: true, label: "用户类型", width: "170px" },
                { prop: "usagesName", label: "用途", width: "170px" },
                { prop: "action", slot: true, label: "操作", width: "170px" },
            ],
            pagination: {
                page: 1,
                pageSize: 10,
                total: 0,
            },
            // 发起需求表单字段
            edit_form: {
                type: 1, // 审批状态赛选
                ownerType: null,//用户类型
                aircraftName: null,//无人机型号
                manufacturerName: null,//生产厂商
                page: "1",
                size: "10",
                purpose: null,//用途
                productType: null,//产品类型
                areaBelongs: null,//所属辖区
                purchaseChannel: null,//购买渠道
                userIdPhone: null,
                messageStatus: null,//审批状态
            },
            selectList: null, // 赛选的机构
            drd: {
                // 所属辖区
                prefectureList: [
                    { id: 1, title: '许村镇' },
                    { id: 2, title: '长安镇' },
                    { id: 3, title: '周王庙镇' },
                    { id: 4, title: '盐官镇' },
                    { id: 5, title: '丁桥镇' },
                    { id: 6, title: '斜桥镇' },
                    { id: 7, title: '袁花镇' },
                    { id: 8, title: '黄湾镇' },
                    { id: 9, title: '硖石街道' },
                    { id: 10, title: '海洲街道' },
                    { id: 11, title: '海昌街道' },
                    { id: 12, title: '马桥街道' },
                ],
                // 产品类型列表
                listProductList: [],
                // 购买渠道列表
                purchaseChannelList: [
                    { id: 1, title: '线上' },
                    { id: 2, title: '线下' },
                    { id: 3, title: '其它' },
                ],
                // 用户类型
                cates: [
                    { id: 1, title: "个人" },
                    { id: 2, title: "单位" },
                ],
                scenes: null,
                uav_type: [],
                mount_type: [],
                task_type: [],
                task_cate: [],
                flightLine: [],
                proStatus: [
                    { id: 1, label: "待处理", value: 0 },
                    { id: 2, label: "处理完成", value: 1 },
                    { id: 3, label: "无需处理", value: 2 },
                ],
                messageStatusList: [

                    {
                        id: 3,
                        name: '审核通过'
                    }, {
                        id: 4,
                        name: '审核不通过'
                    }
                ],
                // 审核状态
                messageStatus: {
                    1: '待完善', 2: '待审核', 3: '审核通过', 4: '审核不通过'
                },
                typeList: []
            },
            approvalList: null,
            infoFlag: true,
            // 是否有审批权限
            isJurisdiction:false

        }
    },
    created() {
        this.drd.prefectureList.forEach(element => { //根据账号修改下拉框
            if (this.user_info_hn.name.indexOf(element.title.substr(0,2)) > -1) {
                this.drd.prefectureList = [element]
            }
        });
        this.init_haves()
        this.async_get_list()
        this.listUsage()
        this.listProductFn()
    },
    mounted() {
        this.baseUrl = process.env.VUE_APP_WS_IP;
    },
    methods: {
        init_haves(){
          const permissions = JSON.parse(localStorage.getItem("permissions"));
          const permissionList = permissions.map((item) => item.permissionSign);
          if (permissionList.indexOf("civilAircraft:register") > -1) {
            this.isJurisdiction= true
          }
    },
        async airUpload(file) {
            let res = null;
            let formData = new FormData();
            formData.append("file", file.file);
            try {
                res = await API.AIRCRAFT.aircraftImport(formData);
                if (res.code === 200) {
                    this.$message.success("数据添加成功!");
                    this.pagination.page = 1
                    this.async_get_list()
                } else {
                    this.$message.error("数据添加失败,请按照模板上传文件!");
                }
            } catch (error) {
                console.log(error);
            }
        },
        onUploadSuccess(response, file, fileList) {
            console.log(response, file, fileList, 'response, file, fileList');
        },
        // 查询产品类型列表
        async listProductFn() {
            let res = await API.AIRCRAFT.listProduct()
            if (res.code == 200) {
                this.drd.listProductList = res.data
            }
        },
        fetch() {
            let dataList = JSON.parse(JSON.stringify(this.dataSource2))
            dataList.forEach((v, index) => {
                v.index = index + 1
                v.ownerType = v.ownerType == 1 ? '个人' : '单位'
                v.isBuyInsurance = v.isBuyInsurance == 1 ? '否' : '是'
                v.messageStatus = this.drd.messageStatus[v.messageStatus]
                v.ownerIdNo = "'" + v.ownerIdNo 
                v.flightControlAccount = "'" + v.flightControlAccount 
            })
            return dataList
        },
        showImg(urlList) {
            if (urlList) {
                console.log(urlList);
                let arr = JSON.parse(JSON.stringify(urlList))
                this.imgList = arr.split(',')
                this.src = this.imgList[0]
                // this.ImgDialogFlag = true
                this.$viewerApi({
                    images:this.imgList
                })
            } else {
                this.$message.warning('暂无附件')
            }
        },
        closeIDsPopupDialog() {
            this.infoFlag = true
            this.approvalList = null
            this.textarea = null;
            this.$forceUpdate()
        },
        back() {
            this.approvalList = null
            this.textarea = null;
            this.infoFlag = true
            this.isApproval = null
            this.$forceUpdate()

        },
        async approvalHandClick() {
            if (!this.isApproval) return this.$message.warning('请选择审批结果！')
            if (!this.textarea) return this.$message.warning('请输入审批意见！')
            let res = await API.AIRCRAFT.approval({
                id: this.approvalList?.id,
                messageStatus: this.isApproval,
                auditRemark: this.textarea
            })
            if (res.code == 200) {
                this.back()
                this.$message.success('操作成功')
                this.pagination.page = 1
                this.async_get_list()
            } if (res.code == 201) {
                return this.$message.warning('已审批，无需审批')
            }
        },
        // 审批
        approval(approval) {
            if (approval) {
                let data = this.dataSource.filter(item => item.id == approval.id)
                this.approvalList = JSON.parse(JSON.stringify(data[0]))
            }
            this.infoFlag = false
        },
        reset() {
            this.edit_form = {  // 发起需求表单字段
                type: 1, // 审批状态赛选
                ownerType: null,//用户类型
                aircraftName: null,//无人机型号
                manufacturerName: null,//生产厂商
                page: "1",
                size: "10",
                purpose: null,//用途
                productType: null,//产品类型
                areaBelongs: null,//所属辖区
                purchaseChannel: null,//购买渠道
                userIdPhone: null,
                messageStatus: null,//审批状态


            }
            this.async_get_list()
        },
        getColor(color) {
            return `color: ${color}`
        },
        approvalStatusClick(num) {
            this.edit_form.type = num + 1
            if (num + 1 == 1) {
                this.edit_form.messageStatus = null
            }
            this.$forceUpdate()
            this.searchData()

        },
        searchData() { // 搜索按钮
            this.pagination.page = 1
            this.async_get_list()
        },
        closeIDsPopup() {
            this.$emit('closeDemand');
        },
        async async_get_list(page) {
            if (page) {
                this.pagination.page = page
            }
            for (const key in this.pagination) {
                if (this.edit_form[key]) {
                    this.edit_form[key] = this.pagination[key]
                }
            }
            let res = await API.AIRCRAFT.PageList(this.edit_form)
            if (res.code == 200 && res.data.length > 0) {
                this.dataSource = res.data.map((item, index) => {
                    return ({
                        ...item,
                        index: index + 1
                    })
                })
                this.pagination.total = res.total
                //  导出
                let form = JSON.parse(JSON.stringify(this.edit_form))
                form.size = res.total
                let DC = await API.AIRCRAFT.PageList(form)
                if (DC.code == 200) {
                    this.dataSource2 = DC.data
                }
            } else {
                this.dataSource = []
            }

        },
        async listUsage() {
            let res = await API.AIRCRAFT.listUsage()
            if (res.code == 200) {
                this.drd.typeList = res.data
            }

        }

    },
}
</script>
<style lang="scss" scoped>
.f4f7 {
    color: #77f4f7
}

.Box {
    min-height: 50vh;

    .tableSelect {
        display: flex;
        justify-content: flex-start;

        .item {
            cursor: pointer;
            display: flex;
            text-align: center;
            justify-content: space-evenly;
            align-items: center;
        }

        .dsp {
            background-image: url(~@/assets/images/civilAircraft/1.png);

            &.selected {
                background-image: url(~@/assets/img/click-3.png);
            }
        }

        .sptg {
            background-image: url(~@/assets/images/civilAircraft/2.png);

            &.selected {
                background-image: url(~@/assets/img/click-1.png);
            }
        }
    }

    .select {
        margin: 32px 0 24px 0;



        .button {
            margin-right: 10px;
            width: 210px;
        }

        .search-button {
            display: inline-block;
            width: 54px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            margin-left: 10px;
            cursor: pointer;
            border-radius: 4px;
            background-color: #000;
            color: #fff;
            text-align: center;
            box-sizing: border-box;
        }


    }

    .task-table {
        height: 55vh;
    }
}

.btn {
    padding: 5px 10px;
    display: inline-block;
    background: rgba(38, 71, 238, 0.71);
    box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
    border-radius: 2px;
    border: 1px solid;
    // border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #FFFFFF;
    margin-right: 10px;
    cursor: pointer;
}

.btn:hover {
    background: rgba(38, 71, 238, 0.91);

}

.djInfoBox {
    display: flex;
    height: 73vh;
    overflow-y: auto;

    .dec {
        width: 4px;
        height: 17px;
        background: #FFC300;
        border-radius: 2px;
    }

    .item {
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        justify-content: space-between;

        div {
            min-width: 30%;
            margin-bottom: 20px;
            flex: 1;
            color: #8799b7;
            white-space: nowrap;
            vertical-align: top !important;

            span {
                display: inline-block;
                overflow: hidden;
                vertical-align: bottom;
                white-space: nowrap;
                text-overflow: ellipsis;
                width: 200px;
                margin-left: 20px;
                color: #effaff;
            }
        }
    }

}

::v-deep {
    .el-divider--vertical {
        height: 100%;
    }

    .el-textarea__inner {
        display: inline-block;
        box-sizing: border-box;
        background: rgba(12, 34, 67, 0.5);
        background: transparent;
        border: 1px solid #009aff;
        border-radius: 0;
        font-size: 16px;
        color: #9fc4ff;
        letter-spacing: 0;
        font-weight: 400;

    }

    .el-textarea.is-disabled .el-textarea__inner {
        background: rgba(12, 34, 67, 0.5) !important;
    }

    .el-dialog {
        margin-top: 5vh;
    }



}

::v-deep {
    .el-input__inner {
        background-color: #000;
        border: 0;
        // height: 40px;
        padding: 20px;
        line-height: 40px;
        color: #fff;
        font-size: 14px;
        .el-input__suffix{
            line-height: 40px;
        }
    }
}

::v-deep .el-divider {
    background-color: #6d6b9654;
}
</style>
<template>
    <div>
        <Dialog v-if="approvalVisible" :create="true" @on-close="closeIDsPopup" title="航空器租借" :left="140" :top="105"
            :width="1748">
            <!--待审批 -->
            <div class="Box">

                <!-- 赛选栏 -->
                <div class="select">
                    <el-input v-model="edit_form.name" class="w240 mr10 dib " size="mini" placeholder="请输入借用人或借出人姓名" clearable>
                    </el-input>
                    <el-select v-model="edit_form.aircraftType" size="mini" clearable placeholder="请选择借用类型"
                        class="button">
                        <el-option v-for="item in drd.cates" :key="item.id" :label="item.title" :value="item.id" />
                    </el-select>
                    <el-select v-model="edit_form.approvalStatus" size="mini" clearable placeholder="请选择审批状态"
                        class="button">
                        <el-option v-for="item in drd.approvalStatus" :key="item.id" :label="item.title"
                            :value="item.id" />
                    </el-select>
                    <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" class="mr10" v-model="edit_form.startTime"
                        type="datetime" placeholder="请选择开始时间">
                    </el-date-picker>
                    <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" class="mr10" v-model="edit_form.endTime"
                        type="datetime" placeholder="请选择结束时间">
                    </el-date-picker>
                    <div class="btn" @click="reset">重置</div>
                    <div class="btn" @click="searchData">查询</div>


                </div>
                <!-- 表格 -->
                <Table class="task-table" :columns="columns" :dataSource="dataSource" :pagination="pagination"
                    @size-change="
                    (size) => {
                        pagination.size = size;
                        async_get_list();
                    }" @page-change="(page) => { async_get_list(page) }">

                    <template #index="{ row }">
                        <div class="action">
                            {{ row.index }}
                        </div>
                    </template>
                    <template #approvalStatus="{ row }">
                        <div class="action" v-if="row.approvalStatus">
                            {{ approvalStatus[row.approvalStatus].title }}
                        </div>
                    </template>
                    <template #borrowUserType="{ row }">
                        <div class="action" v-if="row.borrowUserType">
                            <!-- 借用类型 -->
                            {{ row.borrowUserType == 1 ? '个人' : '单位' }}
                        </div>
                    </template>
                    <template #aircraftType="{ row }">
                        <div class="action">
                            <!-- 无人机类型 -->
                            {{ aircraftType[row.aircraftType] }}
                        </div>
                    </template>
                    <template #action="{ row }">
                        <div class="action">
                            <div v-if="edit_form.type == 1">
                                <el-tooltip content="审批" placement="top" effect="light">
                                    <div class="cp iconfont f4f7 f20 icon-baogaoshenhe" @click="approval(row)"></div>
                                </el-tooltip>
                            </div>
                            <div v-else>
                                <el-tooltip content="详情" placement="top" effect="light">
                                    <div class="cp iconfont f4f7 f20 icon-chakanxiangqing" @click="approval(row)"></div>
                                </el-tooltip>
                            </div>


                        </div>
                    </template>
                </Table>
            </div>
        </Dialog>
        <Dialog v-else :create="true" @on-close="back" title="借用申请详情" :left="140" :top="105" :width="1000">
            <div class="djInfoBox cf wh100">
                <div class="wh100" v-if="approvalList != null">
                    <div class="mb20 f16"><span class="dec dib mr10"></span>借用人信息</div>
                    <div class="item">
                        <div>姓名:<span>{{ approvalList.borrowUser }}</span></div>
                        <div>证件类型：<span>身份证</span></div>
                        <div>证件号码：<span>{{ approvalList.borrowIdNumber }}</span></div>
                        <div>联系手机：<span>{{ approvalList.borrowPhone }}</span></div>
                        <div>用户类型：<span>{{ approvalList.borrowUserType == 1 ? '个人' : '单位' }}</span></div>
                        <div></div>
                    </div>

                    <el-divider></el-divider>
                    <div class="mb20 f16"><span class="dec dib mr10"></span>出借人信息</div>
                    <div class="item">
                        <div>姓名:<span>{{ approvalList.lendUser }}</span></div>
                        <div>证件类型：<span>身份证</span></div>
                        <div>联系手机：<span>{{ approvalList.lendPhone }}</span></div>
                        <div>证件号码：<span>{{ approvalList.lendIdNumber	 }}</span></div>
                        <div>用户类型：<span>{{ approvalList.lendUserType == 1 ? '个人' : '单位' }}</span></div>
                        <div></div>
                    </div>
                    <el-divider></el-divider>

                    <div class="mb20 f16"><span class="dec dib mr10"></span>出借信息</div>
                    <div class="item">
                      
                        <div>借用航空器类型：<span>{{ approvalList.aircraftType == 1 ? '无人机 ' : approvalList.aircraftType == 2
                                ? '航空模型' : '其他'
                        }}</span></div>
                        <div>借用开始时间：<span>{{ approvalList.borrowStartTime }}</span></div>
                        <div>借用结束时间：<span>{{ approvalList.borrowEndTime }}</span></div>

                        <div></div>
                    </div>
                    <div class="jcsb "><span class="dib w120 c7e"> 借用说明:</span>
                        <el-input type="textarea" :rows="2" v-model="approvalList.borrowRemark" disabled>
                        </el-input>
                    </div>
                    <el-divider></el-divider>

                    <div class="mb20 f16"><span class="dec dib mr10"></span>审批记录</div>
                    <div class="item">
                        <div>审批结果:<span>{{ approvalList.approvalStatus == 1 ? '待同意 ' : approvalList.aircraftType == 2 ?
                                '同意' : '不同意'
                        }}</span></div>
                        <div>审批人：<span>{{ approvalList.lendUser }}</span></div>
                        <div>审批时间：<span>{{ approvalList.approvalTime }}</span></div>
                        <div></div>
                    </div>
                    <div class="jcsb "><span class="dib w120 c7e"> 审批备注:</span>
                        <el-input type="textarea" :rows="2" v-model="approvalList.approvalRemark" disabled>
                        </el-input>
                    </div>
                    <div class="mt20 tc wih00" style="margin:0 auto">
                        <div class=" btn" @click="back">返回</div>


                    </div>

                </div>

            </div>
        </Dialog>


    </div>
</template>
<script>
import Dialog from "@/pages/civilAircraft/components/dialog"
import Table from "@/components/common/table-new-hwb"; // 列表组件
import API from "@/api";
export default {
    components: { Dialog, Table },

    data() {
        return {
            textarea: '',
            approvalVisible: true,
            approvalStatusNum: [
                {
                    title: '待审批',
                    num: 12,
                    default: 'dsp',
                    icon: require('@/assets/images/civilAircraft/dsp.svg'),
                    color: "#50F9F4FF"

                },
                {
                    title: '已审批',
                    num: 12,
                    default: "sptg",
                    icon: require('@/assets/images/civilAircraft/sptg.svg'),
                    color: "#00C4FF"

                }
            ],
            dataSource: [

            ],

            columns: [
                { prop: "index", label: "序号", slot: true, width: "100px" },
                { prop: "createTime", label: "时间", width: "230px" },
                { prop: "borrowUser", label: "借用人姓名", width: "170px" },
                { prop: "borrowPhone", label: "借用人手机号", width: "170px" },
                { prop: "borrowUserType", slot: true, label: "借用类型", width: "170px" },
                { prop: "aircraftType", slot: true, label: "无人机类型", width: "170px" },
                { prop: "borrowStartTime", label: "借用开始时间", width: "170px" },
                { prop: "borrowEndTime", label: "借用结束时间", width: "170px" },
                { prop: "borrowRemark", label: "借用说明", width: "170px" },
                { prop: "approvalStatus", slot: true, label: "申请状态", width: "170px" },
                { prop: "action", slot: true, label: "操作", width: "170px" },
            ],
            pagination: {
                page: 1,
                pageSize: 10,
                total: 0,
            },
            aircraftType: {
                1: '无人机',
                2: '航空模型',
                3: '其他',
            },
            edit_form: {  // 发起需求表单字段
                "aircraftType": null,
                "approvalStatus": null,
                "endTime": "",
                "name": "",
                "page": 1,
                "size": 10,
                "startTime": ""
            },
            selectList: null, // 赛选的机构
            approvalStatus: {
                1: { title: "待同意", color: "color:'#e1ab3c'" },
                2: { title: "申请同意", color: "color:#52c759" },
                3: { title: "不同意", color: "color:#e33438" }
            },
            drd: {
                cates: [
                    { id: 1, title: "无人机" },
                    { id: 2, title: "航空模型" },
                    { id: 3, title: "其它" },

                ],
                approvalStatus: [
                    { id: 1, title: "待同意" },
                    { id: 2, title: "申请同意" },
                    { id: 3, title: "不同意" },
                ],
            },
            approvalList: null
        }
    },
    created() {
        this.async_get_list()
    },
    methods: {
        back() {
            this.approvalList = null
            this.textarea = null;
            this.approvalVisible = true
            this.$forceUpdate()
        },
        async approvalHandClick(num) {
            if (this.textarea == '') return this.$message.warning('请输入备注信息！')
            let res = await API.AIRCRAFT.approval({
                id: this.approvalList?.id,
                messageStatus: num,
                auditRemark: this.textarea
            })
            if (res.code == 200) {
                this.back()
                this.$message.success('操作成功')
                this.pagination.page = 1
                this.async_get_list()
            } if (res.code == 201) {
                return this.$message.warning('已审批，无需审批')
            }
        },
        // 审批
        approval(approval) {
            if (approval) {
                let data = this.dataSource.filter(item => item.id == approval.id)
                this.approvalList = JSON.parse(JSON.stringify(data[0]))
            }
            this.approvalVisible = false
        },
        reset() {
            this.edit_form = {  // 发起需求表单字段
                "aircraftType": null,
                "approvalStatus": null,
                "endTime": "",
                "name": "",
                "page": 1,
                "size": 10,
                "startTime": ""
            }
            this.async_get_list()

        },
        searchData() { // 搜索按钮
            this.pagination.page = 1
            this.async_get_list()
        },
        closeIDsPopup() {
            this.$emit('closeDemand');
        },
        async async_get_list(page) {
            if (page) {
                this.pagination.page = page
            }
            for (const key in this.pagination) {
                if (this.edit_form[key]) {
                    this.edit_form[key] = this.pagination[key]
                }
            }
            let res = await API.AIRCRAFT.borrowList(this.edit_form)
            if (res.code == 200 && res.data.length > 0) {
                this.dataSource = res.data.map((item, index) => {
                    return ({
                        ...item,
                        index: index + 1
                    })
                })
                this.pagination.total = res.total
            }else{
                this.dataSource =[]
                this.pagination.total = res.total

            }

        }
    },
}
</script>
<style lang="scss" scoped>
.f4f7 {
    color: #77f4f7
}

.c7e {
    color: #6c7e9b
}

.Box {
    height: 70vh;
    overflow-y: auto;

    .tableSelect {
        display: flex;
        justify-content: flex-start;

        .item {
            cursor: pointer;
            display: flex;
            text-align: center;
            justify-content: space-evenly;
            align-items: center;
        }

        .dsp {
            background-image: url(~@/assets/images/civilAircraft/1.png);

            &.selected {
                background-image: url(~@/assets/img/click-3.png);
            }
        }

        .sptg {
            background-image: url(~@/assets/images/civilAircraft/2.png);

            &.selected {
                background-image: url(~@/assets/img/click-1.png);
            }
        }
    }

    .select {
        margin: 0px 0 24px 0;

        ::v-deep {
            .el-input__inner {
                background-color: #000;
                border: 0;
                height: 30px;
                line-height: 30px;
                color: #fff;
                font-size: 14px;
            }
        }

        .button {
            margin-right: 10px;
            width: 210px;
        }

        .search-button {
            display: inline-block;
            width: 54px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            margin-left: 10px;
            cursor: pointer;
            border-radius: 4px;
            background-color: #000;
            color: #fff;
            text-align: center;
            box-sizing: border-box;
        }


    }

    .task-table {
        height: 60vh;
    }
}

.btn {
    padding: 5px 10px;
    display: inline-block;
    background: rgba(38, 71, 238, 0.71);
    box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
    border-radius: 2px;
    border: 1px solid;
    // border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #FFFFFF;
    margin-right: 10px;
    cursor: pointer;
}

.btn:hover {
    background: rgba(38, 71, 238, 0.91);

}

.djInfoBox {
    display: flex;

    .dec {
        width: 4px;
        height: 17px;
        background: #FFC300;
        border-radius: 2px;
    }

    .item {
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        justify-content: space-between;

        div {
            min-width: 30%;
            margin-bottom: 20px;
            flex: 1;
            color: #8799b7;
            white-space: nowrap;

            span {
                display: inline-block;
                overflow: hidden;
                vertical-align: bottom;
                white-space: nowrap;
                text-overflow: ellipsis;
                width: 200px;
                margin-left: 20px;
                color: #effaff;
            }
        }
    }

}

::v-deep {
    .el-divider--vertical {
        height: 100%;
    }


    .el-textarea__inner {
        display: inline-block;
        box-sizing: border-box;
        background: rgba(12, 34, 67, 0.5) !important;
        background: transparent;
        border: 1px solid #009aff;
        border-radius: 0;
        font-size: 16px;
        color: #9fc4ff;
        letter-spacing: 0;
        font-weight: 400;
    }

    .el-dialog {
        margin-top: 5vh;
    }

}

::v-deep .el-input__icon {
    line-height: 30px;
}
::v-deep .el-divider {
    background-color:#6d6b9654 ;
}
// 
</style>